/**
 * Header layout
 *
 * Copyright © 2018 Royal Canin. All rights reserved.
 */

/**
 * Header layout.
 *
 * Example HTML:
 *
 * <div class="l-header">
 *     <div class="child-view">
 *        <h1>I'm child view</h1>
 *    </div>
 * </div>
 */

/* Mobile First
   ========================================================================== */

.l-header,
.l-sub-header {
  height: $header-height;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
}

.l-header {
  z-index: 4;
}

.l-sub-header {
  height: $sub-header-height;
  top: 105px;
  z-index: 3;
}

/* Responsive design
   ========================================================================== */

/**
 * Devices between (960px and up)
 */
@media only screen and (min-width: 960px) {
  .l-header {
    height: $header-height--desktop;
  }

  .l-sub-header {
    height: $sub-header-height--desktop;
    top: 90px;
  }
}
