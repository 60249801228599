/**
 * Buttons
 *
 * Copyright © 2018 Royal Canin. All rights reserved.
 */

@mixin rc-button-base {
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  margin: 0;
  outline: 0;
  overflow: visible;
  padding: 0 16px;
  position: relative;
  -webkit-tap-highlight-color: transparent;
  text-align: center;
  text-decoration: none;
  transition: background-color 300ms ease-in;
  user-select: none;
  vertical-align: baseline;
}

.rc-button {
  @include rc-button-base();

  background-color: transparent;
  border: 0;
  font-family: DINProMedium, sans-serif;
  font-size: 16px;

  &__title {
    color: $grey-dark;
  }

  &__title--link {
    border-bottom: 1px solid $grey-lighter;
    transition: border-bottom-color 300ms ease-in-out;

    &:hover {
      border-bottom-color: $grey-dark;
    }
  }

  &__title-red {
    color: $primary;
  }

  &__title--link-red {
    border-bottom: 1px solid $primary;
    transition: border-bottom-color 300ms ease-in-out;

    &:hover {
      border-bottom-color: $error;
    }
  }

  &__separator {
    margin-right: 15px;
  }

  &--link {
    color: inherit;
    min-width: inherit;
    padding: 0;
    text-decoration: none;
  }

  &--filled-regular,
  &--filled-small,
  &--outline-regular,
  &--outline-small {
    background-color: $primary;
    border-radius: 30px;

    .rc-button__title {
      color: $white;
    }
  }

  &--filled-regular,
  &--outline-regular {
    height: 48px;
    padding: 0 34px;
  }

  &--filled-small,
  &--outline-small {
    height: 40px;
    min-width: 100px;

    .rc-button__title {
      font-size: 14px;
    }
  }

  &--outline-regular,
  &--outline-small {
    background-color: transparent;
    border: 2px solid $primary;

    .rc-button__title {
      color: $primary;
    }
  }

  /* Hover */

  &--filled-regular:hover,
  &--filled-small:hover {
    background-color: $primary-dark;
  }

  &--outline-regular:hover,
  &--outline-small:hover {
    border-color: $primary-dark;
  }

  /* Disabled */

  &:disabled {
    cursor: not-allowed;

    .rc-icon {
      opacity: 0.3;
    }

    .rc-button__title--link {
      color: $grey-lighter;
    }

    .rc-button__title--link:hover {
      border-bottom-color: $grey-lighter;
    }
  }

  &--filled-regular:disabled,
  &--filled-small:disabled {
    background-color: $grey-lighter;
    cursor: not-allowed;
  }

  &--outline-regular:disabled,
  &--outline-small:disabled {
    border-color: $grey-lighter;
    cursor: not-allowed;

    .rc-button__title {
      color: $grey-lighter;
    }
  }

  /* Icon with text */

  &__icon {
    display: inline-block;
    margin-right: 10px;
    vertical-align: middle;
  }

  [class^='rc-icon'] {
    margin-right: 10px;
  }
}

.rc-button-wrapper {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.rc-button-group {
  position: relative;

  .rc-button__separator {
    margin-right: 10px;
  }
}

.rc-fab-button {
  @include rc-button-base();

  background-color: $primary;
  border-radius: 50%;
  height: 48px;
  min-width: 0;
  padding: 0;
  width: 48px;

  &:hover {
    background-color: darken($primary, 4%);
  }
}

.rc-link,
.rc-link-footer {
  color: $grey-dark;
  cursor: pointer;
  font-family: $din-medium;
  transition: all 300ms ease-in;
}

.rc-link {
  text-decoration: underline;

  &:hover {
    color: $primary;
  }

  &--disabled {
    cursor: default;
    opacity: 0.3;

    &:hover {
      border-color: $grey-lighter;
      pointer-events: none;
    }
  }

  &--warning,
  &--error {
    float: right;
    margin-right: 15px;
  }

  &--warning {
    border-color: $warning;
    color: $warning;
  }

  &--error {
    border-color: $error;
    color: $error;
  }
}

.rc-link-footer {
  border-bottom: 1px solid $grey-lighter;

  &:hover {
    border-color: $grey-dark;
  }
}
