/**
 * Position
 *
 * The role of position is to have all default settings positions.
 *
 * Copyright © 2019 Royal Canin. All rights reserved.
 */

.vcenter {
  top: 50%;
  transform: translateY(-50%);
}
