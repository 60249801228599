@use '@angular/material' as mat;

.mat-ink-bar {
  height: 4px !important;
}

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.all-component-typographies();
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue.
$candy-app-primary: mat.define-palette(mat.$red-palette, 700);
$candy-app-accent: mat.define-palette(mat.$pink-palette, 200, 100, 400);

// The warn palette is optional (defaults to red).
$candy-app-warn: mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$candy-app-theme: mat.define-light-theme(
  (
    color: (
      primary: $candy-app-primary,
      accent: $candy-app-accent,
      warn: $candy-app-warn,
    ),
    typography: mat.define-typography-config(),
    density: 0,
  )
);

// The following mixins include base theme styles that are only needed once per application. These
// theme styles do not depend on the color, typography, or density settings in your theme. However,
// these styles may differ depending on the theme's design system. Currently all themes use the
// Material 2 design system, but in the future it may be possible to create theme based on other
// design systems, such as Material 3.
//
// Please note: you do not need to include the 'base' mixins, if you include the corresponding
// 'theme' mixin elsewhere in your Sass. The full 'theme' mixins already include the base styles.
//
// To learn more about "base" theme styles visit our theming guide:
// https://material.angular.io/guide/theming#theming-dimensions
//
// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-bases($candy-app-theme);
@include mat.core-theme($candy-app-theme);
@include mat.datepicker-theme($candy-app-theme);
