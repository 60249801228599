/**
 * Product layout
 *
 * Copyright © 2018 Royal Canin. All rights reserved.
 */

/* Mobile design
   ========================================================================== */

.l-product {
  margin: 0;
}

/* Responsive design
   ========================================================================== */

@media (min-width: 760px) {
  .l-product {
    margin: 0 auto;
    max-width: 1190px;
    padding: 15px;
  }
}
