/**
 * Title
 *
 * Copyright © 2018 Royal Canin. All rights reserved.
 */

/**
 * RC title
 *
 * Example HTML:
 *
 * <h1 class="rc-title-alpha">Hello World</h1>
 *
 */

/**
 * 1. H1
 * 2. H2
 * 3. H3
 * 4. H4
 * 5. H5
 * 6. H6
 */

/* Title Style
   ========================================================================== */

.rc-title-alpha, /* 1 */
.rc-title-beta, /* 2 */
.rc-title-gamma, /* 3 */
.rc-title-delta, /* 4 */
.rc-title-epsilon, /* 5 */
.rc-title-zeta /* 6 */ {
  color: $primary;
  font-family: $din-regular;
  font-weight: normal;
  margin: 0;
  padding: 0;
  position: relative;
}

.rc-title-alpha {
  font-size: 30px;
  line-height: 34px;
}

.rc-title-beta {
  font-size: 26px;
  line-height: 32px;
}

.rc-title-gamma {
  font-size: 22px;
  line-height: 28px;
}

.rc-title-delta {
  font-size: 18px;
  line-height: 24px;
}

.rc-title-epsilon {
  font-size: 16px;
  line-height: 24px;
}

.rc-title-zeta {
  font-size: 14px;
  line-height: 24px;

  &--medium {
    color: $grey-dark;
    font-family: $din-medium;
  }
}

.pointer {
  cursor: pointer;
}

/**
 * RC title
 *
 * Example HTML:
 *
 * <p class="rc-paragraph rc-paragraph--intro">Hello World</h1>
 *
 */

/* Paragraph Style
   ========================================================================== */

.rc-paragraph {
  color: $grey;
  font-family: $din-regular;
  font-size: 14px;
  line-height: 24px;
  margin: 0;
  padding: 0;

  &--bold {
    font-family: $din-bold;
  }

  &--medium {
    font-family: $din-medium;
  }

  &--intro {
    font-size: 16px;
    line-height: 24px;
  }

  &--large-intro {
    font-size: 18px;
    line-height: 32px;
  }

  &--large-body {
    font-size: 16px;
    line-height: 32px;
  }

  &--body {
    font-weight: bold;
  }

  &--numeric {
    font-size: 20px;
    line-height: 32px;
  }

  &--meta {
    font-size: 12px;
    line-height: 16px;

    &-medium {
      font-weight: 700;
    }
  }
}

/* Icon + Title Style
   ========================================================================== */

[class^='rc-title'] > [class^='rc-icon'] {
  display: inline-block;
  left: 5px;
  margin: 0 20px 0 30px;
  position: relative;
  top: 5px;
}

.rc-title--right-icon > [class^='rc-icon'] {
  left: inherit;
  margin: 0;
  position: absolute;
  right: 0;
  top: 42%;
  transition: 500ms cubic-bezier(1, 0, 0.18, 0.99);

  &.active {
    top: 0;
  }
}

/* Responsive design
   ========================================================================== */

@media only screen and (min-width: 960px) {
  .rc-title-alpha {
    font-size: 40px;
    line-height: 48px;
  }

  .rc-title-beta {
    font-size: 30px;
    line-height: 34px;
  }

  .rc-title-gamma {
    font-size: 26px;
    line-height: 32px;
  }

  .rc-title-delta {
    font-size: 20px;
    line-height: 24px;
  }

  .rc-title-epsilon {
    font-size: 18px;
    line-height: 24px;
  }

  .rc-title-zeta {
    font-size: 16px;
    line-height: 24px;
  }

  .rc-paragraph {
    font-size: 16px;
    line-height: 24px;

    &--intro {
      font-size: 18px;
      line-height: 26px;
    }

    &--large-intro {
      font-size: 22px;
      line-height: 36px;
    }

    &--large-body {
      font-size: 18px;
      line-height: 34px;
    }

    &--numeric {
      font-size: 22px;
      line-height: 36px;
    }

    &--meta {
      font-size: 14px;
      line-height: 18px;
    }
  }
}

@media print {
  .rc-paragraph {
    color: $black;
  }
}
