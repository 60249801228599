/**
 * Input
 *
 * Copyright © 2018 Royal Canin. All rights reserved.
 */

/* Input
   ========================================================================== */

/**
 * RC Input
 *
 *
 * Example HTML:
 *
 * <input type="text" placeholder="Hello world" class="rc-input">
 *
 */

/**
 * 1. Chrome/Opera/Safari
 * 2. Firefox 19+
 * 3. IE 10+
 * 4. Firefox 18-
 */

.rc-input-container {
  position: relative;

  &--search {
    margin: 0 auto;
    position: relative;
    width: 100%;
  }

  .rc-icon--search {
    bottom: 17px;
    height: 16px;
    position: absolute;
    width: 16px;
  }
}

.rc-input {
  appearance: none;
  background: none;
  border: 0;
  border-radius: 0;
  color: $grey;
  font-family: DINProMedium, sans-serif;
  font-size: 16px;
  margin: 0;
  min-width: 30px;
  padding: 0;
  width: 100%;

  &--underlined {
    border-bottom: 2px solid $grey-lighter;
    height: 50px;
  }

  &-button {
    cursor: pointer;
    margin-left: 20px;
    position: relative;
    top: 25%;
  }

  &--error {
    border-color: $error;
    color: $error;
  }

  &--error::placeholder {
    color: $error;
  }

  &--warning {
    border-color: $warning;
    color: $warning;
  }

  &--warning::placeholder {
    color: $warning;
  }

  &--warning:disabled {
    color: $warning;
  }

  &--ok {
    border-color: $success;
    color: $success;
  }

  &--ok::placeholder {
    color: $success;
  }

  &--ok:disabled {
    color: $success;
  }

  &--info-message,
  &--ok-message,
  &--error-message,
  &--warning-message {
    font-size: 11.2px;
    height: 0;
    margin: 3px 0 -3px;
  }

  &--error-message {
    color: $error;
  }

  &--warning-message {
    color: $warning;
  }

  &--ok-message {
    color: $success;
  }

  &--search {
    padding-left: 25px;
  }
}

.rc-input__wrapper {
  align-items: center;
  display: flex;
}

.rc-input-textarea {
  appearance: none;
  border: 1px solid $grey-lighter;
  height: 150px;
  padding: 10px;
  resize: none;
  width: 100%;
}

/**
 * Radio input type
 */
.rc-input-radio {
  /* The container */
  &_container {
    border: 0;
    cursor: pointer;
    display: inline;
    height: 25px;
    margin-right: 50px;
    padding-left: 25px;
    position: relative;
    user-select: none;
  }

  /* Hide the browser's default radio button */
  &_container input {
    cursor: pointer;
    opacity: 0;
    position: absolute;
  }

  /* Create a custom radio button */
  &_checkmark {
    background-color: $white;
    border: 1px solid $grey-light;
    border-radius: 50%;
    height: 20px;
    left: 0;
    position: absolute;
    top: 0;
    width: 20px;
  }

  &_rounded-checkmark {
    background-color: $white;
    border: 1px solid $grey-lighter;
    border-radius: 50%;
    height: 24px;
    width: 24px;
  }

  &_rounded-checkmark::after {
    background: $primary;
    border-radius: 50%;
    content: '';
    display: none;
    height: 8px;
    left: 7px;
    position: relative;
    top: 7px;
    width: 8px;
  }

  /* Create the indicator (the dot/circle - hidden when not checked) */
  &_checkmark::after {
    content: '';
    display: none;
    position: absolute;
  }

  /* Style the indicator (dot/circle) */
  &_container .rc-input-radio_checkmark::after {
    background: $primary;
    border-radius: 50%;
    height: 10px;
    left: 4px;
    top: 4px;
    width: 10px;
  }

  /* Show the indicator (dot/circle) when checked */
  &_container input:checked ~ .rc-input-radio_checkmark::after {
    display: block;
  }

  &_container input:checked ~ .rc-input-radio_rounded-checkmark::after {
    display: block;
  }
}

input:checked ~ .rc-input-radio_checkmark {
  background-color: $white;
}

input:hover input ~ .rc-input-radio_checkmark {
  background-color: $grey;
}

/*
 Responsive design
 ========================================================================== */

/**
 * Devices between (960px and up)
 */
@media only screen and (min-width: 960px) {
  .rc-input-container .rc-icon--search {
    background-position: -66px -216px;

    &:hover::after {
      background-position: initial;
      opacity: 0;
    }
  }
}
