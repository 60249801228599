/**
 * Action buttons
 *
 * Copyright © 2018 Royal Canin. All rights reserved.
 */

.rc-action-circle {
  background-color: $primary;
  border-radius: 999px;
  display: inline-block;
  height: 32px;
  position: relative;
  width: 32px;
}

.rc-action-icon {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.rc-action-plus__icon-plus {
  background-color: $white;
  height: 2px;
  width: 10px;
}

.rc-action-plus__icon-plus--vertical {
  position: relative;
  top: 2px;
}

.rc-action-plus__icon-plus--horizontal {
  transform: rotate(90deg);
}
