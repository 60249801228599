/**
 * Table
 *
 * Copyright © 2018 Royal Canin. All rights reserved.
 */

/* Table
   ========================================================================== */

.rc-table {
  display: table;
  position: relative;
  width: 100%;

  &__row {
    display: table-row;
    height: 100px;

    &--header {
      display: none;
    }
  }

  &__cell {
    border-top: 1px solid $app-background;
    display: table-cell;
    position: relative;
    vertical-align: middle;

    &--title {
      color: $grey-dark;
      font-family: $din-medium;
      font-size: 12px;
      text-transform: uppercase;
    }
  }

  &__product {
    display: block;
    position: relative;

    &-image {
      display: inline-block;
      vertical-align: middle;
      width: 50px;

      img {
        width: 100%;
      }
    }

    &-name {
      display: inline-block;
      font-family: $din-regular;
      padding: 0 10px;
      vertical-align: middle;
    }
  }
}

/*
 Responsive design
 ========================================================================== */

/**
 * Devices between (960px and up)
 */
@media only screen and (min-width: 960px) {
  .rc-table__row {
    height: 80px;

    &--header {
      display: table-row;
    }
  }
}

@media print {
  .rc-table__row--header {
    display: table-row;
  }
}
