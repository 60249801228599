/**
 * Select
 *
 * Copyright © 2018 Royal Canin. All rights reserved.
 */

/* Select
   ========================================================================== */

.rc-select {
  cursor: pointer;
  display: inline-block;
  position: relative;
  white-space: nowrap;
  width: 100%;

  &.is-disabled {
    cursor: not-allowed;
    opacity: 0.3;
  }

  &.is-active {
    border-bottom-color: $grey;
  }

  .rc-icon--arrow-down {
    position: relative;
    top: 5px;
  }

  &:hover:not(.is-disabled) .rc-icon--arrow-down,
  .rc-icon--arrow-down.active {
    background-position: -176px -100px;
  }

  .rc-icon--arrow-down.active {
    top: 0;
  }

  &-wrapper {
    border-bottom: 2px solid $grey-lighter;
    display: inline-block;
    vertical-align: middle;
    white-space: nowrap;
    width: 100%;
  }

  &__value-wrapper,
  &__arrow-wrapper {
    display: inline-block;
    height: 48px;
    line-height: 48px;
    white-space: nowrap;
  }

  &__value-wrapper {
    padding-right: 20px;
    width: 100%;
  }

  &__arrow-wrapper {
    float: right;
    position: absolute;
    right: 0;

    &.active {
      transform: rotate(180deg);
      transform-origin: center;
    }
  }

  &__placeholder,
  &__value-text {
    font-family: DINProMedium, sans-serif;
    font-size: 16px;
  }

  &__value-text {
    color: $grey;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }

  &-prefix {
    cursor: pointer;
    display: inline-block;
    height: 40px;
    left: 10px;
    position: relative;
    vertical-align: middle;
    width: 40px;
  }

  &-prefix &-prefix__icon {
    display: inline-block;
    height: 25px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 25px;
  }

  &--has-prefix {
    vertical-align: middle;
    width: calc(100% - 35px);
  }

  &--active {
    border-color: $grey;
  }

  &__loading-bar {
    height: 2px;
    overflow: hidden;
    position: relative;
    top: -2px;
    width: 100%;

    &--loading {
      background: rgb(226 0 26 / 15%);

      &::before,
      &::after {
        background: $primary;
        bottom: 0;
        content: '';
        left: 0;
        position: absolute;
        top: 0;
      }

      &::before {
        animation: bar-1 2s cubic-bezier(0.65, 0.81, 0.73, 0.4) infinite;
      }

      &::after {
        animation: bar-2 2s cubic-bezier(0.16, 0.84, 0.44, 1) infinite;
        animation-delay: 1.15s;
      }
    }
  }
}

.rc-select-list {
  background-color: $white;
  border: 1px solid $grey-lighter;
  display: none;
  max-height: 200px;
  -ms-overflow-style: none;
  overflow-y: auto;
  position: absolute;
  width: 100%;
  z-index: 11;

  &.is-opened {
    display: block;
  }
}

.rc-option {
  position: relative;

  &__text {
    background-color: $white;
    cursor: pointer;
    display: block;
    overflow: hidden;
    padding: 16px 10px;
    text-align: left;
    text-overflow: ellipsis;
    transition: background-color 250ms ease-in-out;
    white-space: nowrap;

    &:hover {
      background-color: $app-background;
    }
  }
}

@keyframes bar-1 {
  0% {
    left: -35%;
    right: 100%;
  }

  60%,
  100% {
    left: 100%;
    right: -90%;
  }
}

@keyframes bar-2 {
  0% {
    left: -200%;
    right: 100%;
  }

  60%,
  100% {
    left: 107%;
    right: -8%;
  }
}
