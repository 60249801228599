@import 'styles/utils/all';

.mdc-dialog__container .mat-mdc-dialog-surface {
  display: block;
  padding: $size-7;
}

.mat-mdc-dialog-surface:has(.wrapper__close) {
  padding-top: 0;
}

.mat-mdc-dialog-surface:has(.wrapper--no-padding-bottom) {
  padding-bottom: 0;
}

.rc-overlay-container,
.rc-overlay-wrapper {
  height: 100%;
  left: 0;
  pointer-events: none;
  top: 0;
  width: 100%;
}

.rc-overlay-container {
  position: fixed;
  z-index: 700;
}

.rc-overlay-backdrop {
  bottom: 0;
  left: 0;
  opacity: 0;
  pointer-events: auto;
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  z-index: 500;

  &--dark {
    background: rgb(0 0 0 / 32%);
  }

  &--showing {
    opacity: 1;
  }
}

.rc-overlay-wrapper {
  position: absolute;
  z-index: 500;
}

.rc-overlay-pane {
  max-height: 100%;
  max-width: 100%;
  pointer-events: auto;
  position: static;
  z-index: 500;
}

.customMatDialogOverlay {
  background: $grey-darker !important;
  opacity: 0.8 !important;
}

.cdk-overlay-pane,
.cdk-overlay-pane .mdc-dialog__container {
  @include from($desktop) {
    background-color: transparent !important;
  }
}

.popinDefaultPanelCustom .mdc-dialog__container {
  background-color: $white;
  height: 100vh;
  width: 100vw;
  @include from($desktop) {
    height: 70vh;
    width: rem(800px);
  }
}

.reportProductPanelCustom .mdc-dialog__container {
  background-color: $white;
  height: 100vh;
  width: 100vw;
  @include from($tablet) {
    height: rem(580px);
    width: rem(920px);
  }
}

.reportNoProductsPanelCustom .mdc-dialog__container {
  background-color: $white;
  height: 100vh;
  width: 100vw;
  @include from($tablet) {
    height: rem(580px);
    width: rem(600px);
  }
}

.productDetailPanelCustom .mdc-dialog__container {
  background-color: $white;
  height: 100vh;
  width: 100vw;
  @include from($desktop) {
    height: rem(580px);
    width: rem(920px);
  }
}

.shareRecomendationPanelCustom .mdc-dialog__container {
  background-color: $white;
  height: 100vh;
  width: 100vw;
  @include from($desktop) {
    height: rem(900px);
    width: rem(1280px);
  }
}

.addressFormPetOwnerPanelCustom .mdc-dialog__container {
  background-color: $white;
  height: 100vh;
  width: 100vw;
  @include from($desktop) {
    height: rem(820px);
    width: rem(920px);
  }
}

.addressFormClinicPanelCustom .mdc-dialog__container {
  background-color: $white;
  height: 100vh;
  width: 100vw;
  @include from($desktop) {
    height: rem(750px);
    width: rem(920px);
  }
}

.infoPopinPanelCustom .mdc-dialog__container {
  background-color: $white;
  height: 100vh;
  width: 100vw;
  @include from($desktop) {
    height: rem(500px);
    width: rem(440px);
  }
}

.searchProductPopin .mdc-dialog__container {
  background-color: $white;
  height: 100vh;
  width: 100vw;
  @include from($desktop) {
    height: rem(452px);
    width: rem(440px);
  }
}

.productPurchase .mdc-dialog__container {
  background-color: $white;
  height: 100vh;
  width: 100vw;
  @include from($desktop) {
    height: rem(420px);
    width: rem(953px);
  }
}

.emailPoppinPanelCustom {
  background-color: $white;
  height: 100vh;
  width: 100vw;

  @include from($desktop) {
    height: rem(530px);
    width: rem(1160px);
  }
}

.uploadLogoPopinPanelCustom {
  background-color: $white;
  height: 100vh;
  width: 100vw;

  @include from($desktop) {
    height: rem(490px);
    width: rem(440px);
  }
}

@media only screen and (min-width: 960px) {
  .rc-overlay-wrapper {
    align-items: center;
    display: flex;
    justify-content: center;
  }

  .cdk-overlay-pane,
  .rc-overlay-pane {
    display: flex;
    // max-width: 80vw;

    &.big {
      width: 1160px;
    }

    &.plus {
      width: 920px;
    }

    &.medium {
      width: 920px;
    }

    &.regular {
      width: 580px;
    }

    &.small {
      width: 440px;
    }
  }
}

.contactPopinPanel .mdc-dialog__container {
  background-color: $white;
  height: 100vh;
  width: 100vw;
  @include from($tablet) {
    height: rem(750px);
    width: rem(920px);
  }
}

.exitPricesPopinPanel .mdc-dialog__container {
  background-color: $white;
  height: 100vh;
  width: 100vw;
  @include from($tablet) {
    height: rem(500px);
    width: rem(450px);
  }
}

.switchWlpPopinPanel .mdc-dialog__container {
  background-color: $white;
  height: 100vh;
  @include from($tablet) {
    height: rem(572px);
    width: rem(920px);
  }
}

.seePreview .mdc-dialog__container .mat-mdc-dialog-surface {
  padding: 0;
}
