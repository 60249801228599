@import 'styles/utils/site-variables';

$height-dialog: 50vh;

.rc-dialog {
  background-color: $white;
  display: block;
  height: calc(100% - 65px);
  overflow: auto;
  padding: 0;
  position: fixed;
  width: 100%;
  z-index: 1000;

  &-left,
  &-right {
    float: inherit;
  }

  &-left .rc-title-gamma {
    margin-bottom: 10px;
  }

  &-right {
    margin-top: 50px;
  }

  &-header,
  &-title,
  &-content,
  &-actions {
    display: block;
    position: relative;
  }

  &-header {
    height: 65px;
    width: 100%;
  }

  &-close {
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 35px;
  }

  &-content {
    height: 100%;
    position: relative;

    &--vertical-align {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  &-wrapper {
    display: block;
    padding: 0 30px;
  }
}

.rc-dialog-form {
  margin: $size-4 0;
  position: relative;

  &__block {
    width: 100%;
  }

  &__input {
    padding-bottom: 20px;
  }

  &__action {
    margin-bottom: 17.5px;
    margin-top: 25px;
    text-align: center;
  }
}

@media only screen and (min-width: 375px) and (max-width: 736px) and (orientation: landscape) {
  .rc-dialog-right {
    margin-top: 10px;
  }
}

@media only screen and (min-width: 960px) {
  .rc-dialog {
    height: inherit;
    position: relative;

    &-left {
      float: left;
    }

    &-right {
      float: right;
    }

    /* Dialog Header */
    &-header {
      height: 75px;
    }

    /* Dialog Content */
    &-content {
      margin: 0;
      max-height: 65vh;
      overflow: auto;
      -webkit-overflow-scrolling: touch;
      padding: 0;

      &--vertical-align {
        display: block;
      }
    }
  }

  .rc-dialog-wrapper {
    height: 100%;
    width: 100%;
  }

  .rc-dialog-form {
    padding-bottom: 0;

    &--vertical-align {
      align-items: center;
      display: flex;
      height: 100%;
      justify-content: center;
    }

    &__action {
      margin-bottom: 35px;
      margin-top: 30px;
      text-align: center;

      &--align-bottom {
        bottom: 0;
        margin-bottom: 50px;
        margin-top: 0;
        position: absolute;
        right: 0;
      }
    }
  }

  .plus .rc-dialog-form__action--align-bottom {
    position: relative;
    text-align: start;
  }

  .plus .rc-dialog-wrapper {
    display: flex;
    margin: 0 auto;
    position: relative;

    .rc-dialog-left,
    .rc-dialog-right {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 50%;
    }

    .rc-dialog-left {
      &__content {
        max-width: 320px;
        position: fixed;
      }
    }

    .rc-title-gamma {
      margin-bottom: 30px;
    }

    .rc-paragraph {
      max-width: 300px;
    }

    .rc-dialog-right {
      margin: 0;
    }
  }

  .big .rc-dialog-wrapper,
  .medium .rc-dialog-wrapper {
    display: block;
    margin: 0 auto;
    position: relative;

    .rc-dialog-left,
    .rc-dialog-right {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 50%;

      &--vertical-align {
        height: $height-dialog;
      }
    }

    .rc-dialog-left {
      height: $height-dialog;

      &__content {
        max-width: 320px;
        position: fixed;
      }
    }

    .rc-title-gamma {
      margin-bottom: 30px;

      &--thin {
        max-width: 75%;
      }
    }

    .rc-paragraph {
      max-width: 300px;
    }

    .rc-dialog-right {
      margin: 0;
    }
  }

  .big .rc-dialog-wrapper {
    width: 1013px;
  }

  .medium .rc-dialog-wrapper {
    width: 750px;
  }

  .fullscreen,
  .fullscreen .rc-dialog {
    height: 100%;
    overflow: hidden;
    width: 100vw;
  }

  .fullscreen .rc-dialog-content {
    max-height: initial;
  }
}

@media only screen and (min-width: 1440px) and (min-height: 960px) {
  .rc-dialog-content {
    max-height: inherit;
  }
}
